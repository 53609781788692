import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components';

const TableBox = ({data}) => {
  return (
    <StyledTable>
      <tbody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <ImageCell>
              <Image src={row.image} alt={`Row ${index + 1}`} />
            </ImageCell>
            <TextCell>
              {row.text.map((line, i) => (
                <TextLine key={i}>{line}</TextLine>
              ))}
            </TextCell>
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 73%;
`;

const TableRow = styled.tr`
  border: 1px solid #ccc;
`;

const ImageCell = styled.td`
  width: 10%;
  padding: 30px;
`;

const TextCell = styled.td`
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transform: scale(0.8);
`;

const TextLine = styled.div`
  line-height: 1.8;
`;

export default TableBox