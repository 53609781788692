import React from 'react'
import COLOR from '../../global/Color'
import styled from 'styled-components';

const ContentContainer = ({children, backgroundImage}) => {
  return (
    <OuterContainer $backgroundImage={backgroundImage}>
      {children}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 90px;
  padding-bottom: 40px;
  background-color: ${props => props.theme.COLOR.WHITE};
  background-image: url(${props => props.$backgroundImage});
  background-position: center;
  background-size: cover;
  position: relative;


    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      display: none;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      display: none;
    }
`;

export default ContentContainer