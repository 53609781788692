import React from 'react'
import styled from 'styled-components';

const DivideLine = () => {
  return (
    <OuterContainer>
      <InnerDivider />
    </OuterContainer>
  );
  }
  
  const OuterContainer = styled.div`
    width: 100%;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  
  const InnerDivider = styled.div`
    width: 73%;
    height: 100%;
    border-bottom: 1px solid #ccc;
  `;
  

export default DivideLine