import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'

import TelematicsImage01 from '../../assets/technologies/telematicsImage01.png';
import AutomotiveImage03 from '../../assets/technologies/automotiveImage03.png'


const Telematics = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Telematics</HeaderTitle>

        <div style={{height: '60px'}}></div>

        <HeaderContentSmall>
          Telematics는 통신을 의미하는 Telecommunication과 정보과학을 의미하는 Informatics의 합성어로 차량의 위치 파악 기술과 무선통신 기술을 활용하여 차량 내 정보단말기를 통해 <br></br>
          운전자 및 탑승자에게 주행, 안전, 생활정보, 엔터테인먼트 등과 관련된 유용한 정보와 서비스를 제공하는 시스템을 지칭한다.
        </HeaderContentSmall>

        <div style={{width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 30}}>
          <img style={{width: '43%', objectFit: 'contain'}} src={TelematicsImage01}/>
          <div style={{width: '54%' }}>
            <p style={{lineHeight: 1.6}}>
              보다 구체적으로는 운전자가 무선 네트워크를 통해 차량을 원격 진단하고, 교통 및 생활정보, <br></br>
              주행, 안정 및 차량관리와 관련된 서비스 등 각종 정보를 이용할 수 있으며, <br></br>
              응급상황 감지 및 긴급 조치서비스 등을 제공 받을 수 있다. 즉 운전자와 차량을 무선네트워크를 통하여 <br></br>
              유기적으로 연결, 사용자가 차량을 원격으로 이용/진단 하는 것이 가능 하도록 하며, <br></br>
              차량내부/외부에서 교통정보와 같은 생활 전반의 정보들을 이용할 수 있도록 도와 주는 <br></br>
              IT기술과 자동차산업 기술의 합작품이다.
            </p>

            <p style={{lineHeight: 1.6}}>
              Telematics 서비스는 자동차 메이커와 이동통신업체 간의 합작 형태로 이루어지는 것이 일반적이다. <br></br>
              통신 기술측면에서 볼 때 현재 Telematics 는 4G LTE로 신속하게 이동하고 있으며 향후 5G로 <br></br>
              이동하게 되어 자율주행 에도 영향을 주게 될 것이다.
            </p>

            <p style={{lineHeight: 1.6}}>
              자동차 기술측면에서는 잘 달리는 성능과 기능을 중심으로 개발되기 시작하던 부분에서, <br></br>
              보고 듣고 즐기는 안락함을 추구하는 세대를 지나 이제는 첨단 Telematics system을 통한 <br></br>
              사용편의성과 활용성 향상에 주력하는 세대에 이르렀다.
            </p>
          </div>
        </div>

        <SubHeading>Eplus Solution</SubHeading>

        <ImageTextPanel 
          Image={AutomotiveImage03}
          Title={'Sierra wireless'}
          Link={'(http://www.sierrawireless.com)'}
          Text={<>
            Automakers are using high-speed wireless connectivity to bring sophisticated software and services to the vehicle. <br></br>
            These IoT connections and the “Big Data” they generate can help OEMs continually improve their vehicles and <br></br>
            develop new applications to enhance the driving experience.
          </>}/>

      </HeaderContainer>
    </>
  )
}

export default Telematics