import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import InnerNaviagation from '../../components/navigation/InnerNaviagation'
import Footer from '../../components/container/Footer'
import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderTitle from '../../components/inner/HeaderTitle'

import backgroundImage from '../../assets/company/CompanyBackgroundImage.png'

import About from './About'
import History from './History'

const Company = () => {

    const CompanyItem = ["About", "History"];

    return (
        <DefaultContainer>
            <Header headerBackgroundImage={backgroundImage}>
                <MainNaviagation />
                    <HeaderTitle>Company</HeaderTitle>
                <InnerNaviagation item={CompanyItem}/>
            </Header>
    
            <AboutContainer>
              <Routes>
                <Route path='/' element={<Navigate to="about" replace={false}/> }/>
                <Route path='about' element={<About/>} />
                <Route path='history' element={<History />} />
              </Routes>
            </AboutContainer>
    
            <Footer />
    
        </DefaultContainer>
      )
}

export default Company