import React, { useEffect, useState } from 'react'
import COLOR from '../../../global/Color'
import SIZE from '../../../global/Size'
import '../../navigation/NavigationEffect.css'
import styled from 'styled-components'

const DefaultBtn = ({children}) => {
  return (
    <StyledButton 
      className="animated-button"
      onClick={() => {
        console.log(children);
      }}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.COLOR.CLEAR};
  color: ${props => props.theme.COLOR.WHITE};
  border: 0;
  font-size: ${props => props.theme.SIZE.MEDIUM};
  &.animated-button {
    /* 추가적인 애니메이션 스타일이 있으면 여기에 추가 */
  }

`;


export default DefaultBtn