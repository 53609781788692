import React from "react";
import COLOR from "../../global/Color";
import SIZE from "../../global/Size";
import "./NavigationEffect.css";
import { Link } from "react-router-dom";
import styled from "styled-components";

const InnerNaviagation = ({ item = [] }) => {
  return (
    <FooterContainer>
      {item.map((inner, index) => (
        <StyledLink
          className="animated-button"
          to={`${inner.toLowerCase()}`}
          key={index}
        >
          {inner}
        </StyledLink>
      ))}
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.COLOR.MAGORELLEBLUECLEAR};
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.COLOR.WHITE};
  text-decoration: none;
  width: 8%;
  height: 100%;
  color: ${(props) => props.theme.COLOR.WHITE};
  font-weight: 600;
  font-size: ${(props) => props.theme.SIZE.MEDIUM};
  border: 0;
  text-align: center;
  place-content: center;
  background-color: ${(props) => props.theme.COLOR.CLEAR};
`;

export default InnerNaviagation;
