import React from 'react'
import { Table } from 'react-bootstrap'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const VendorTableBox = ({data}) => {
  return (
    <StyledTable>
      <tbody>
        {data.map((row, index) => (
          <StyledTableRow key={index}>
            <ImageCell>
              <StyledImage src={row.image} alt={`Row ${index + 1}`} />
            </ImageCell>
            <TextCell>
              <Title>{row.title}</Title>
              {row.text.map((line, i) => (
                <TextLine key={i}>{line}</TextLine>
              ))}
            </TextCell>
          </StyledTableRow>
        ))}
      </tbody>
    </StyledTable>
  );
  }
  
  const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
  `;
  
  const StyledTableRow = styled.tr`
    border: 1px solid #ccc;
  `;
  
  const ImageCell = styled.td`
    width: 10%;
    padding: 30px;

    /* 800px 이상 1300px 미만 */
    @media (min-width: 800px) and (max-width: 1299px) {
      display: none;
    }
    
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      display: none;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      display: none;
    }
  `;
  
  const TextCell = styled.td`
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 40px;
    /* 800px 이상 1300px 미만 */
    @media (min-width: 800px) and (max-width: 1299px) {
      padding-left: 40px;
    }
    
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      padding-left: 40px;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      padding-left: 40px;
    }
  `;
  
  const StyledImage = styled.img`
    width: 100%;
    height: auto;
    transform: scale(0.8);
  `;
  
  const Title = styled.span`
    font-weight: 600;
    font-size: ${props => props.theme.SIZE.LARGE};
    line-height: 3;
    /* 800px 이상 1300px 미만 */
    @media (min-width: 800px) and (max-width: 1299px) {
      line-height: 1.5;
    }
    
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      line-height: 1.5;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      line-height: 1.5;
    }
  `;
  
  const TextLine = styled.div`
    line-height: 1.8;
  `;
  

export default VendorTableBox