import React from 'react'
import styled from 'styled-components';

const TwoImage = ({ Image01, Image02}) => {
  return (
    <OuterContainer>
      <StyledImage src={Image01} />
      <StyledImage src={Image02} />
    </OuterContainer>
  );
}

  const OuterContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  `;
  
  const StyledImage = styled.img`
    width: 49%;
    height: auto;
    object-fit: contain;
  `;
  

export default TwoImage