import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import DefaultContainer from "../../components/container/DefaultContainer";
import Header from "../../components/container/Header";
import MainNaviagation from "../../components/navigation/MainNaviagation";
import InnerNaviagation from "../../components/navigation/InnerNaviagation";
import Footer from "../../components/container/Footer";
import AboutContainer from "../../components/container/company/AboutContainer";
import HeaderTitle from "../../components/inner/HeaderTitle";

import backgroundImage from "../../assets/vendorProduct/VendorBackgroundImage.png";

import Goodix from "./Goodix";
import Omnivision from "./Omnivision";
import SierraWireless from "./SierraWireless";
import Syncomm from "./Syncomm";
import Byd from "./Byd";
import Catl from "./Catl";

const Vendor = () => {
  const CompanyItem = ["Goodix", "Omnivision", "Syncomm"];

  return (
    <DefaultContainer>
      <Header headerBackgroundImage={backgroundImage}>
        <MainNaviagation />
        <HeaderTitle>Vendor & Product</HeaderTitle>
        <InnerNaviagation item={CompanyItem} />
      </Header>

      <AboutContainer>
        <Routes>
          <Route path="/" element={<Navigate to="goodix" replace={false} />} />
          <Route path="/goodix" element={<Goodix />} />
          <Route path="/omnivision" element={<Omnivision />} />
          <Route path="/syncomm" element={<Syncomm />} />
          {/* <Route path="/sierra wireless" element={<SierraWireless />} /> */}
          {/* <Route path="/byd" element={<Byd />} /> */}
          {/* <Route path="/catl" element={<Catl />} /> */}
        </Routes>
      </AboutContainer>

      <Footer />
    </DefaultContainer>
  );
};

export default Vendor;
