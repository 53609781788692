import React from 'react'
import COLOR from '../../../global/Color'
import styled from 'styled-components';

const AboutContainer = ({children}) => {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.COLOR.WHITE}; // 대문자로 된 키 사용
`;

export default AboutContainer