import React from 'react'
import styled from 'styled-components';

const ThreeImagePanel = ({children, Image01, Image02, Image03}) => {
  return (
    <OuterContainer>
      <ImageRow>
        <StyledImage src={Image01} />
        <StyledImage src={Image02} />
        <StyledImage src={Image03} />
      </ImageRow>
      <ContentContainer>
        {children}
      </ContentContainer>
    </OuterContainer>
  );
}
  
  const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
  `;
  
  const ImageRow = styled.div`
    width: 60%;
    display: flex;
    gap: 10px;
  `;
  
  const StyledImage = styled.img`
    width: 32%;
    object-fit: contain;
  `;
  
  const ContentContainer = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 20px;
  `;
  

export default ThreeImagePanel