import React from 'react'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const HorizontalMediaCard = ({ imageSrc1, title1, content1, imageSrc2, title2, content2 }) => {
  return (
    <OuterContainer>
      <Column>
        <StyledImage src={imageSrc1} alt={title1} />
        <Title>{title1}</Title>
        <Content>{content1}</Content>
      </Column>
      <Column>
        <StyledImage src={imageSrc2} alt={title2} />
        <Title>{title2}</Title>
        <Content>{content2}</Content>
      </Column>
    </OuterContainer>
  );
  }
  
  const OuterContainer = styled.div`
    width: 73%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 30px;
  `;
  
  const Column = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
  `;
  
  const StyledImage = styled.img`
    width: 98%;
    height: 100%;
    padding-bottom: 30px;
  `;
  
  const Title = styled.span`
    font-size: ${props => props.theme.SIZE.X0LARGE};
    font-weight: 600;
    padding-bottom: 20px;
  `;
  
  const Content = styled.span`
    line-height: 1.5;
  `;
  

export default HorizontalMediaCard