import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import COLOR from '../../global/Color'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import FullImage from '../../components/inner/venderProduct/FullImage'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import VendorTableBox from '../../components/inner/venderProduct/VendorTableBox'

import GoodixImage01 from '../../assets/vendorProduct/GoodixImage01.png';
import TableImage01 from '../../assets/vendorProduct/GoodixTable01.png'
import TableImage02 from '../../assets/vendorProduct/GoodixTable02.png'
import TableImage03 from '../../assets/vendorProduct/GoodixTable03.png'
import TableImage04 from '../../assets/vendorProduct/GoodixTable04.png'
import TableImage05 from '../../assets/vendorProduct/GoodixTable05.png'
import TableImage06 from '../../assets/vendorProduct/GoodixTable06.png'
import TableImage07 from '../../assets/vendorProduct/GoodixTable07.png'

const GoodixTableItem01 = [
  {
    image: TableImage01,
    title: 'IN-DISPLAY Fingerprint Sensor',
    text: [
      'Goodix의 IN-DISPLAY FINGERPRINT SENSOR™는 bezelless 스마트폰 디자인의 진화를 주도합니다. ',
      '지문 스캔 및 인증을 가능하게 하고 디스플레이 아래의 광학 지문 센서를 통해 물리적 지문 센서 버튼이 필요하지 않습니다.'
    ]
  },
  {
    image: TableImage02,
    title: 'Live Finger Detection',
    text: [
      'Hardware와 Software 인증 솔루션을 결합한 세계 최초의 라이브 지문 센서인 Live Finger Detection™을 사용하면',
      '정전식 지문 센서와 광학 센서를 하나로 매끄럽게 결합할 수 있습니다.'
    ]
  },
  {
    image: TableImage03,
    title: 'Covered Fingerprint Sensors',
    text: [
      'Goofix Fingerprint sensor는 다양한 cover (Ceramic, Glass, Sapphire)에 적용이 가능합니다.',
      ''
    ]
  }
]

const GoodixTableItem02 = [
  {
    image: TableImage04,
    title: 'Touch controller for Mobile',
    text: [
      'Goodix의 touchscreen controller는 LCD/AMOLED 용의 On-Cell, Out-Cell처럼',
      '여러 stack의 display에 적용 가능하며 최적의 touch solution을 제공합니다.'
    ]
  },
  {
    image: TableImage05,
    title: 'Touch controller for Tablet',
    text: [
      '풍부한 제품 포트폴리오는 최고 수준의 플래그십 제품에 이르기까지 태블릿 시장을 완벽하게 포괄하므로 엔터테인먼트,',
      '온라인 교육, 비즈니스, 전자 책 및 메모 작성을 포함한 다양한 애플리케이션 시나리오의 요구에 부합하여 시장을 선도하고 있습니다.'
    ]
  },
  {
    image: TableImage06,
    title: 'Touch controller for Laptops',
    text: [
      'Windows OS 및 Chrome OS에 완벽하게 적응하는 제품 군이며, 일반 노트북과 비즈니스 및 교육용 노트북에 적합합니다.',
      '이 제품은 업계 최고의 active styles성능을 갖추고 있습니다.'
    ]
  },
  {
    image: TableImage07,
    title: 'Automotive touch controller',
    text: [
      '8~17인치의 자동차 디스플레이 화면을 지원하는 제품들은 다양한 화면 비율에 유연하게 적응합니다.',
      ''
    ]
  }
]

const Goodix = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Goodix</HeaderTitle>
        <HeaderContent>
          <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>Goodix</span> <a style={{textDecoration: 'underline'}} href='http://www.goodix.com' target='_blank'>(http://www.goodix.com)</a>는 2002년 설립되어, <br></br>
          세계적으로 Human Interface 와 Biometric Technologies 를 선구 하는 회사로써 <br></br>
          Mobile Phone, Tablet, Wearable device들과 human 을 이어주는 touch interface 및  <br></br>
          지문인식분야의 최고의 기술력을 보유하고 있는 기업입니다.
        </HeaderContent>

        <HeaderContentSmall>
          많은 경험을 바탕으로 최고의 성능을 제공하며 혁신, Teamwork, 성능 이라는 가치관을 기본으로 세계 최고의 기업들에게 touch 및 <br></br>
          보안 solution 분야에 혁신적인 성능을 제공하여 가치를 향상 시키고 있습니다.
        </HeaderContentSmall>

        <FullImage Item={GoodixImage01}/>

        <SubHeading>지문인식 sensor</SubHeading>

        <HeaderContentSmall>
          Goodix는 항상 보안을 최우선으로 생각합니다. 모바일 장치, 스마트 홈 및 자동차 제품의 애플리케이션을 통해 업계 최고의 생체 인식 솔루션을 혁신하고 <br></br>
          최종 사용자의 데이터 개인 정보 보호 및 가정 보안을 보장하는 안전하고 편리한 경험을 제공하는 데 전념해 왔습니다. <br></br>
          Goodix는 IN-DISPLAY FINGERPRINT SENSOR™, Live Finger Detection™, IFS™, covered(유리, 세라믹, 사파이어) 지문 센서 및 <br></br>
          하드 코팅된 지문 센서를 포함한 전체 범위의 지문 솔루션을 제공하는 유일한 공급업체입니다.
        </HeaderContentSmall>

        <VendorTableBox data={GoodixTableItem01}/>

        <div style={{height: '2%'}}></div>

        <SubHeading>Touch Controllers</SubHeading>

        <HeaderContentSmall>
          Goodix는 고급 human-interface solution으로 진정한 터치 경험을 제공합니다.  <br></br>
          Goodix의 human-interface 제품은 전 세계적으로 10억 개 이상의 장치에 적용되어 있습니다. <br></br>
          디스플레이 기술과 스마트 모바일 기기의 발전으로 우리는 계속해서 기술을 발전시키고 <br></br>
          human-interface solution의 혁신적인 적용을 확대하여 정확한 제어와 편리한 상호 작용을 통해 소비자에게 높은 기술력을 제공합니다.
        </HeaderContentSmall>

        <VendorTableBox data={GoodixTableItem02}/>

        <div style={{height: '100px'}}></div>
        
      </HeaderContainer>

    </>
  )
}

export default Goodix