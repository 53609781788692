import React from 'react'
import SIZE from '../../global/Size'
import styled from 'styled-components';

const HeaderContent = ({children}) => {
  return (
    <TextContainer>
      {children}
    </TextContainer>
  )
}

const TextContainer = styled.div`
  font-size: ${props => props.theme.SIZE.X0LARGE};
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 1.7;
  
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      font-size: ${props => props.theme.SIZE.LARGE};
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      font-size: ${props => props.theme.SIZE.LARGE};
    }

`;

export default HeaderContent