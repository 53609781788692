import React from 'react'
import SIZE from '../../global/Size'
import styled from 'styled-components';

const HeaderTitle = ({children}) => {
  return <TextContainer>{children}</TextContainer>;
}

const TextContainer = styled.div`
  font-size: ${props => props.theme.SIZE.X1LARGE}; // 대문자로 된 키 사용
  font-weight: 600;

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      font-size: ${props => props.theme.SIZE.X05LARGE};
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      font-size: ${props => props.theme.SIZE.X05LARGE};
    }
`;


export default HeaderTitle