import React from 'react'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const SubHeading = ({children}) => {
  return (
    <TextContainer>
      {children}
    </TextContainer>
  );
  }
  
  const TextContainer = styled.div`
    font-size: ${props => props.theme.SIZE.X05LARGE};
    font-weight: 600;
    padding-bottom: 20px;
  `;
  

export default SubHeading