import React from 'react'
import styled from 'styled-components';

const TwoImageBox = ({ Image01, Image02 }) => {
  return (
    <Container>
      <StyledImage src={Image01} />
      <StyledImage src={Image02} />
    </Container>
  );
  }
  
  const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;
  
  const StyledImage = styled.img`
    width: 48%;
  `;

export default TwoImageBox