import React from 'react'
import COLOR from '../../../../global/Color'
import styled from 'styled-components';

const FifthInnerItem = ({children, Icon, title }) => {
  return (
    <Container>
      <IconImage src={Icon} />
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 34%;
  left: 25%;
  line-height: 2.5;
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    left: 15%;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    flex-direction: column;
    align-items: flex-start;
    left: 15%;
    top: 20%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
    align-items: flex-start;
    left: 15%;
    top: 20%;
  }
`;

const IconImage = styled.img`
  width: 2.2%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }

`;

const Title = styled.div`
  padding-left: 1%;
  color: ${props => props.theme.COLOR.DARKGRAY};

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
  
  `;

const Content = styled.div`
  padding-left: 13%;
  position: absolute;
  font-weight: 500;
  overflow: auto;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

export default FifthInnerItem