import React from 'react'
import styled from 'styled-components';

const SummaryContainer = ({children}) => {
  return (
      <OuterContainer>
        <InnerContainer>
          {children}
        </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const InnerContainer = styled.div`
  width: 80%;
  height: 100%;
`;

export default SummaryContainer