import React from 'react'
import styled from 'styled-components';

const ContentTitleBlack = ({children}) => {
    return (
        <TextContainer>
            {children}
        </TextContainer>
        );
}

const TextContainer = styled.div`
  font-size: ${props => props.theme.SIZE.X1LARGE};
  font-weight: 600;
  color: ${props => props.theme.COLOR.BLACK};
  position: relative;
  left: 13.5%;
`;

export default ContentTitleBlack