import React from 'react'

const Theme = {
    COLOR : {
        BLUEDIAMOND : '#4F3373',
        BUTTERFLYBUSH : '#70578B',
        PRELUDE : '#BDA7C8',
        LAVENDER : '#F2E2F8',
        WHITE : '#FFFFFF',
        WHITESMOKE : '#E9E9E9',
        BLACKPEARL : '#15181C',
        BLACK : '#000000',
        CLEAR : '#00000000',
        CLEARLESS : '#00000030',
        MORECLEARLESS : '#00000050',
        DARKGRAY : '#666666',
        MAGORELLEBLUE : '#643FDA',
        MAGORELLEBLUECLEAR : '#643FDA99',
        BLUE : '#4B89DC',
    },
    SIZE : {
        SMALL : '12px',
        MEDIUM : '16px',
        MEDIUM2 : '18px',
        LARGE : '20px',
        XLARGE : '24px',
        X0LARGE : '26px',
        X05LARGE : '32px',
        X1LARGE : '40px',
        X2LARGE : '48px',
        X25LARGE : '60px',
        X3LARGE : '72px',
        X4LARGE : '96px'
    }
}

export default Theme