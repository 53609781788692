import React from 'react'
import backgroundImage from '../../../assets/main/Rectangle3.png'
import styled from 'styled-components';

const ThirdContainer = ({children}) => {
  return <Container $backgroundImage={backgroundImage}>{children}</Container>;
}

const Container = styled.div`
  width: 100%;
  height: 800px;
  background-color: #ccc;
  background-image: url(${props => props.$backgroundImage});
  background-position: center;
  background-size: cover;

  /* 450px 이상 800px 미만 */
  @media (min-width: 601px) and (max-width: 799px) {
    height: 700px;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 600px) {
    height: 500px;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    height: 400px;
  }
`;

export default ThirdContainer