import React from 'react'
import styled from 'styled-components';

const CenterAlignedContainer = ({children}) => {
  return (
    <OuterContainer>
      {children}
    </OuterContainer>
  );
  }
  
  const OuterContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 50px;
    flex-direction: column;
  `;
  

export default CenterAlignedContainer