
const COLOR = {
    BLUEDIAMOND : '#4F3373',
    BUTTERFLYBUSH : '#70578B',
    PRELUDE : '#BDA7C8',
    LAVENDER : '#F2E2F8',
    WHITE : '#FFFFFF',
    WHITESMOKE : '#E9E9E9',
    BLACKPEARL : '#15181C',
    BLACK : '#000000',
    CLEAR : '#00000000',
    CLEARLESS : '#00000030',
    MORECLEARLESS : '#00000050',
    DARKGRAY : '#666666',
    MAGORELLEBLUE : '#643FDA',
    MAGORELLEBLUECLEAR : '#643FDA99',
    BLUE : '#4B89DC',
}

export default COLOR