import React from 'react'
import SIZE from '../../global/Size'
import styled from 'styled-components';

const HeaderContentSmall = ({children}) => {
  return <TextContainer>{children}</TextContainer>;
}

const TextContainer = styled.div`
  line-height: 1.7;
  font-size: ${props => props.theme.SIZE.MEDIUM2}; // 대문자로 된 키 사용
  padding-bottom: 30px;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.MEDIUM}; // 대문자로 된 키 사용
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.MEDIUM}; // 대문자로 된 키 사용
    }

`;

export default HeaderContentSmall

