import React from 'react'
import SIZE from '../../../global/Size'
import COLOR from '../../../global/Color'
import styled from 'styled-components'

const ImageTextPanel = ({ Image, Title, Link, Text}) => {
  return (
    <OuterContainer>
      <ImageContainer src={Image} />
      <TextContainer>
        <TitleContainer>
          <TitleText>{Title}</TitleText>
          <LinkText href={Link}>{Link}</LinkText>
        </TitleContainer>
        <MainText>{Text}</MainText>
      </TextContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  align-items: center;
`;

const ImageContainer = styled.img`
  width: 20%;
  object-fit: contain;
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    display: none;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
  `;

const TextContainer = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 90%;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 90%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 90%;
  }
`;

const TitleContainer = styled.div`
  font-size: ${props => props.theme.SIZE.MEDIUM};
  padding-bottom: 10px;
`;

const TitleText = styled.span`
  font-size: ${props => props.theme.SIZE.MEDIUM2};
  color: ${props => props.theme.COLOR.MAGORELLEBLUE};
  font-weight: 700;
`;

const LinkText = styled.a`
  font-size: ${props => props.theme.SIZE.MEDIUM2};
  text-decoration: none;
  color: ${props => props.theme.COLOR.BLACK};
`;

const MainText = styled.div`
  font-size: ${props => props.theme.SIZE.MEDIUM};
  line-height: 1.7;
`;

export default ImageTextPanel