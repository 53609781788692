import React from 'react'
import styled from 'styled-components';

const ContactInnerItem = ({ Icon, title, children}) => {
    return (
        <Container>
          <IconImage src={Icon} />
          <Title>{title}</Title>
          <Content>{children}</Content>
        </Container>
      );
    }

const Container = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
`;

const IconImage = styled.img`
    width: auto;
    transform: scale(0.6);
`;

const Title = styled.div`
    width: auto;
    color: ${props => props.theme.COLOR.DARKGRAY};
    margin-right: 10px;
`;

const Content = styled.div`
    width: auto;
    font-weight: 500;
    margin-right: 20px;
`;
    

export default ContactInnerItem