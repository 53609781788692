import React from 'react'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const ImageTextSmall = ({Image, Text}) => {
  return (
    <OuterContainer>
      <StyledImage src={Image} />
      <TextContainer>
        <Paragraph>{Text}</Paragraph>
      </TextContainer>
    </OuterContainer>
  );
}
  
  const OuterContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
  
  const StyledImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
  `;
  
  const TextContainer = styled.div`
    width: auto;
    height: 50%;
    display: flex;
    flex-direction: column;
  `;
  
  const Paragraph = styled.p`
    font-size: ${props => props.theme.SIZE.MEDIUM};
  `;
  

export default ImageTextSmall