import React from 'react'
import COLOR from '../../global/Color'
import styled from 'styled-components';

const ContentBox = ({children}) => {
  return (
    <OuterContainer>
      {children}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

export default ContentBox