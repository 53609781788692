
const SIZE = {
    SMALL : '12px',
    MEDIUM : '16px',
    MEDIUM2 : '18px',
    LARGE : '20px',
    XLARGE : '24px',
    X0LARGE : '26px',
    X05LARGE : '32px',
    X1LARGE : '40px',
    X2LARGE : '48px',
    X3LARGE : '72px',
    X4LARGE : '96px'
}

export default SIZE