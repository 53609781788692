import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SecondInnerItem = ({item, link}) => {
  return (
    <Container>
      <LinkButton to={'/vendor/' + link}>
        <Image src={item} />
      </LinkButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.COLOR.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkButton = styled(Link)`
  width: auto;
  height: auto;
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default SecondInnerItem