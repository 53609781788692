import React from 'react'

import SIZE from '../../global/Size'
import COLOR from '../../global/Color'

import Summary from '../../components/inner/recruit/Summary'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContainerRecurit from '../../components/header/HeaderContainerRecruit'
import SummaryContainer from '../../components/container/recruit/SummaryContainer'

import InfomationImage02 from '../../assets/recruit/Recurit02.png'
import RecuritImage01 from '../../assets/recruit/recruitImage01.png';
import RecuritCircle from '../../assets/recruit/circle.png';
import SummaryImage from '../../assets/recruit/recuritSummary288ppi.png';
import styled from 'styled-components'

const Infomation = () => {
  return (
    <>
      <HeaderContainerRecurit Image={RecuritImage01}>
        <HeaderTitle>Infomation</HeaderTitle>
        <HeaderContent >
          <HeaderContentTitle>
            <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>Eplus Member</span>는 함께 발전을 더해가는 이념으로 우수한 인재를 <br></br>
            상시 모집하고 있습니다.
          </HeaderContentTitle>
        </HeaderContent>
          <HeaderContentSummary>
            <img src={RecuritCircle} style={{width: '15px', height: 'auto', objectFit: 'contain'}} alt="Recruit Circle"/> &nbsp;인력채용 모집요강
          </HeaderContentSummary>
          <ContentSummary>모집부문 및 자격</ContentSummary>
      </HeaderContainerRecurit>
      <SummaryContainer>
        <img src={SummaryImage} style={{width: '100%'}}/>
      </SummaryContainer>
      <Summary />
        
      <img style={{width: '100%'}} src={InfomationImage02} />
    </>
  )
}

const HeaderContentTitle = styled.span`
  font-weight: 500;
  font-size: ${props => props.theme.SIZE.X05LARGE};

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.LARGE};
  }
`;

const HeaderContentSummary = styled.span`
  font-weight: 700;
  display: flex;
  font-size: ${props => props.theme.SIZE.X05LARGE};

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    font-size: ${props => props.theme.SIZE.X0LARGE};
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.X0LARGE};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.LARGE};
  }

  `;

const ContentSummary = styled.span`
  color: ${props => props.theme.COLOR.MAGORELLEBLUE};
  font-size: ${props => props.theme.SIZE.X0LARGE};
  font-weight: 700;
  display: flex;
  margin-top: 6%;


  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.LARGE};
  }

`

export default Infomation