import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import InnerNaviagation from '../../components/navigation/InnerNaviagation'
import Footer from '../../components/container/Footer'
import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderTitle from '../../components/inner/HeaderTitle'

import backgroundImage from '../../assets/technologies/technologiesBackgroundImage.png'

import Automotive from './Automotive'
import BlackBox from './BlackBox'
import Medical from './Medical'
import Mobile from './Mobile'
import Security from './Security'
import Telematics from './Telematics'
import Dems from './Dems'

const Technologies = () => {

  const TechnologiesItem = ["Automotive", "Blackbox", "Medical", "Mobile", "Security", "Telematics", "DEMS"];

    return (
        <DefaultContainer>
            <Header headerBackgroundImage={backgroundImage}>
                <MainNaviagation />
                    <HeaderTitle>Technologies</HeaderTitle>
                <InnerNaviagation item={TechnologiesItem}/>
            </Header>

            <AboutContainer>
              <Routes>
                <Route path='/' element={<Navigate to="automotive" replace={false}/> }/>
                <Route path='/automotive' element={<Automotive/>} />
                <Route path='/blackbox' element={<BlackBox/>} />
                <Route path='/medical' element={<Medical/>} />
                <Route path='/mobile' element={<Mobile/>} />
                <Route path='/security' element={<Security/>} />
                <Route path='/telematics' element={<Telematics/>} />
                <Route path='/dems' element={<Dems/>} />
              </Routes>
            </AboutContainer>
    
            <Footer />
    
        </DefaultContainer>
      )
}


export default Technologies