import React from 'react'
import styled from 'styled-components';

const SecondInnerBox = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  width: 70%;
  height: 100%;
  position: relative;
  top: 25%;
  left: 13%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5%;
  
  
  /* 800px 이상 1000px 미만 */
  @media (min-width: 800px) and (max-width: 1000px) {
    top: 20%;
    height: 50%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    top: 15%;
    height: 50%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    top: 10%;
    height: 30%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

export default SecondInnerBox