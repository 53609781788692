import React from 'react'
import COLOR from '../../../global/Color'
import styled from 'styled-components';

const NoPaddingCenterAlignedContainer = ({children}) => {
  return (
    <OuterContainer>
      <InnerContainer>
        {children}
      </InnerContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.COLOR.WHITE};
  position: relative;
  padding-left: 13.5%;
  padding-right: 13.5%;
  display: flex;
  flex-direction: row;
  padding-bottom: 70px;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default NoPaddingCenterAlignedContainer