import React from 'react'
import COLOR from '../../../global/Color'
import SIZE from '../../../global/Size'
import styled from 'styled-components'

const MainInnerText = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  /* 1500px 이상 */
  @media (min-width: 1500px) {
    width: 100%;
    color: ${props => props.theme.COLOR.WHITE};
    font-size: ${props => props.theme.SIZE.X3LARGE};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 40%;
  }

  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
    width: 100%;
    color: ${props => props.theme.COLOR.WHITE};
    font-size: ${props => props.theme.SIZE.X25LARGE};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 40%;
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 100%;
    color: ${props => props.theme.COLOR.WHITE};
    font-size: ${props => props.theme.SIZE.X2LARGE};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 40%;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
    color: ${props => props.theme.COLOR.WHITE};
    font-size: ${props => props.theme.SIZE.X1LARGE};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 40%;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
    color: ${props => props.theme.COLOR.WHITE};
    font-size: ${props => props.theme.SIZE.XLARGE};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 40%;
  }

`;

export default MainInnerText