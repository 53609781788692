import React from 'react'
import COLOR from '../../../global/Color'
import SIZE from '../../../global/Size'
import styled from 'styled-components'

const InnerItem = ({Title, Content1, Content2, Content3}) => {
  return (
    <OuterContainer>
      <TitleContainer>{Title}</TitleContainer>
      <ContentContainer>{Content1}</ContentContainer>
      <ContentContainer>{Content2}</ContentContainer>
      <ContentContainer>{Content3}</ContentContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.COLOR.WHITE};
  padding-top: 30px;
  box-sizing: border-box;
  padding-bottom: 30px;
`;

const TitleContainer = styled.div`
  font-size: ${props => props.theme.SIZE.XLARGE};
  color: ${props => props.theme.COLOR.MAGORELLEBLUE};
  font-weight: 600;
  left: 50px;
  position: relative;
  padding-bottom: 10px;
  padding-right: 80px;
`;

const ContentContainer = styled.div`
  left: 50px;
  padding-right: 80px;
  position: relative;
  margin-bottom: 10px;
`;


export default InnerItem