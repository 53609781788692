import React, { useEffect, useState } from 'react'
import COLOR from '../../global/Color';
import styled from 'styled-components';

const DefaultContainer = ({children}) => {

      // 화면 가로, 세로 길이를 담는 변수
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  // 실시간으로 변하는 화면의 크기를 구하는 코드
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    
  },[])

  return (
    <OuterContainer $windowSize={windowSize}>
      {children}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: ${props => props.$windowSize.width}px;
  height: ${props => props.$windowSize.height}px;
  background-color: ${props => props.theme.COLOR.BLACK};
`;

export default DefaultContainer