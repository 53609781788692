import React from 'react'
import { Table } from 'react-bootstrap'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};

const SyncommTableBox = ({ data }) => {

    const chunkedData = chunkArray(data, 4);

    return (
        <StyledTable>
          <tbody>
            {chunkedData.map((rowChunk, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {rowChunk.map((row, index) => (
                  <StyledTableCell key={index}>
                    <ImageContainer>
                      <StyledImage
                        src={row.image}
                        alt={`Row ${rowIndex * 4 + index + 1}`}
                      />
                    </ImageContainer>
                    <TextContainer>
                      <Title>{row.title}</Title>
                      {row.text.map((line, i) => (
                        <TextLine key={i}>{line}</TextLine>
                      ))}
                    </TextContainer>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </tbody>
        </StyledTable>
      );
      }
      
      const StyledTable = styled.table`
        border-collapse: collapse;
        width: 73%;
      `;
      
      const StyledTableRow = styled.tr``;
      
      const StyledTableCell = styled.td`
        border: 1px solid #ccc;
        width: 25%;
      `;
      
      const ImageContainer = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `;
      
      const StyledImage = styled.img`
        width: 50%;
        height: auto;
        transform: scale(0.5);
      `;
      
      const TextContainer = styled.div`
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 20px;
        text-align: center;
      `;
      
      const Title = styled.span`
        font-weight: 600;
        font-size: 1.2em;
        line-height: 1.8;
      `;
      
      const TextLine = styled.div`
        line-height: 1.7;
      `;


export default SyncommTableBox