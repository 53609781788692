import React from 'react'
import styled from 'styled-components';

const FullImage = ({Item}) => {
  return (
    <StyledImage src={Item} />
  );
  }
  
  const StyledImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: cover;
    padding-bottom: 50px;
  `;
  

export default FullImage