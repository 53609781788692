import React from 'react'
import styled from 'styled-components';

const Header = ({children, headerBackgroundImage}) => {
  return (
    <OuterContainer $headerBackgroundImage={headerBackgroundImage}>
      {children}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${props => props.$headerBackgroundImage});
  background-position: center;
  background-size: cover;
  position: relative;
`;

export default Header