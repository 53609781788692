import React from 'react'
import COLOR from '../../global/Color'
import styled from 'styled-components';

const HeaderContainerRecurit = ({children, Image}) => {
  return (
    <Container>
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <ImageWrapper>
        <StyledImage src={Image} />
      </ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
  padding-top: 90px;
  background-color: ${props => props.theme.COLOR.WHITE};
  position: relative;
  left: 13.5%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  padding-right: 60px;

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1599px) {
    width: 80%;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 80%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 80%;
  }
`;

const ImageWrapper = styled.div`
  width: 24%;
  height: 100%;
  position: relative;
  left: -100px;


  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1599px) {
    display: none;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  object-fit: contain;
`;

export default HeaderContainerRecurit