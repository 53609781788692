import React from 'react'
import SIZE from '../../global/Size'
import styled from 'styled-components';

const LastHeaderContentSmall = ({children}) => {
  return <TextContainer>{children}</TextContainer>;
}

const TextContainer = styled.div`
  line-height: 1.7;
  font-size: ${props => props.theme.SIZE.MEDIUM2}; // 대문자로 된 키 사용
  padding-bottom: 10px;
`;

export default LastHeaderContentSmall