import React from 'react'
import COLOR from '../../global/Color'
import styled from 'styled-components';

const ContentContainerClear = ({children}) => {
  return (
    <OuterContainer>
      {children}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 90px;
  padding-bottom: 40px;
  background-color: ${props => props.theme.COLOR.WHITE};
  position: relative;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 0px;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 0px;
  }
`;

export default ContentContainerClear