import React from 'react'
import SIZE from '../../../global/Size'
import COLOR from '../../../global/Color'
import styled from 'styled-components';

const SubHeadingColor = ({ children }) => {
  return (
    <TextContainer>
      {children}
    </TextContainer>
  );
  }
  
  const TextContainer = styled.div`
    font-size: ${props => props.theme.SIZE.X0LARGE};
    font-weight: 600;
    padding-bottom: 20px;
    color: ${props => props.theme.COLOR.MAGORELLEBLUE};
  `;
  

export default SubHeadingColor