import React from 'react'
import COLOR from '../../../../global/Color'
import SIZE from '../../../../global/Size'
import styled from 'styled-components'

const FifthInnerTitle = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: 25%;
  left: 25%;
  font-size: ${props => props.theme.SIZE.X1LARGE};
  font-weight: 700;

  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
    top: 20%;
    left: 20%;
  }
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    top: 15%;
    left: 15%;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    top: 15%;
    left: 15%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    top: 15%;
    left: 15%;
  }

`;

export default FifthInnerTitle